import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-header-accueil',
    templateUrl: './header-accueil.component.html',
    styleUrls: ['./header-accueil.component.scss']
})
export class HeaderAccueilComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();
    @Output() seConnecter: EventEmitter<boolean> = new EventEmitter<boolean>();
    localeOptions: { label: string; value: string }[];
    selectedLocale: string;

    button: string;

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('langue')) {
            this.selectedLocale = sessionStorage.getItem('langue');
        } else {
            this.selectedLocale = 'fr';
            sessionStorage.setItem('langue', this.selectedLocale);
        }
        this.translate.setDefaultLang(this.selectedLocale);
        this.localeOptions = [
            {label: 'FR - Français', value: 'fr'},
            {label: 'EN - English', value: 'en'},
        ];
        this.button = this.localeOptions.find(l => l.value === this.selectedLocale).label;
    }

    seConnecterButton() {
        this.seConnecter.emit();
    }

    useLocale(option: string) {
        this.selectedLocale = option;
        this.button = this.localeOptions.find(l => l.value === option).label;
        this.translate.use(option);
        sessionStorage.setItem('langue', this.selectedLocale);
    }

    sInscrire() {
        window.open(environment.urlCreateUser, '_blank');
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }
}
