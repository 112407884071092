<div class="content">
  <div class="page-not-found-page-wrapper">
    <div class="content">
      <div class="text-wrapper fr-col-7">
        <h1 class="title">{{ 'NOT_FOUND.TITRE' | translate}}</h1>
        <p class="fr-">
            {{ 'NOT_FOUND.TEXTE1' | translate}}
        </p>
        <p>{{ 'NOT_FOUND.TEXTE2' | translate}}<a [routerLink]="'/'" target="_self"
                                                                         title="Front page">{{ 'NOT_FOUND.ACCUEIL' | translate}}</a>.</p>
      </div>
    </div>
  </div>

</div>
