import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Authentication} from '../../models/authentication/authentication';
import {Habilitation} from '../../models/habilitation/habilitation';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

    roleLocal =  new Subject<string>();
    unsubscribe$ = new Subject<void>();
    isAuthenticated = false;

    constructor(
        private http: HttpClient) {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    authentification(): Observable<Authentication> {
        return this.http.get<Authentication>(
            `${environment.backendUrl}${environment.backendUri}/authentification`);
    }

    setAuthSession(auth: Authentication) {
        sessionStorage.setItem('authUser', JSON.stringify(auth));
    }

    setAuthSessionLocal(auth: Authentication = new Authentication()) {
        if (!auth?.habilitation) {
            const habi: Habilitation = new Habilitation();
            this.roleLocal.next('CHERCHEUR');
            this.roleLocal.subscribe(val => habi.habilitationLibelle = val);
            auth.habilitation = habi;
        } else {
            this.roleLocal.subscribe(val => auth.habilitation.habilitationLibelle = val);
        }
        auth.email = environment.mail_test;
        sessionStorage.setItem('authUser', JSON.stringify(auth));
    }

  getAuthSession() {
    const auth: Authentication = JSON.parse(sessionStorage.getItem('authUser'));
    return auth;
  }
}
