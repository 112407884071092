<div *ngIf="isAuthenticated$ | async">
    <app-header (clearSession)="logout()"></app-header>
    <main role="main" class="fr-container--fluid fr-pl-15w fr-pr-15w" style="min-height: 600px; position: relative;">
        <app-alerte-message></app-alerte-message>
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </main>
    <app-footer class="fr-container--fluid fr-pl-15w fr-pr-15w" style="position: relative;"></app-footer>
</div>
<div *ngIf="!(isAuthenticated$ | async)">
    <app-header-accueil (seConnecter)="seConnecter()"></app-header-accueil>
    <main role="main" class="fr-container--fluid fr-pl-15w fr-pr-15w" style="min-height: 600px; position: relative;">
        <router-outlet style="min-height: 600px;" name="no"></router-outlet>
    </main>
    <app-footer class="fr-container--fluid fr-pl-15w fr-pr-15w" style="position: relative;"></app-footer>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-climbing-dot" [fullScreen]="true">
    <p style="color: white">Loading...</p>
</ngx-spinner>
