import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss']
})
export class CookiesModalComponent {

  @Input() body: string;
  @Input() title: string;
  @Input() okbutton: string;
  @Input() closeButton: string;

  constructor(public activeModal: NgbActiveModal) {
  }

}
