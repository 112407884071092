<div class="fr-alert fr-alert--error" *ngIf="isDisplay" [@inOutAnimation]>
  <p class="fr-alert__title">Erreur : {{messageError?.title}}</p>
  <p>{{messageError?.text}}</p>
  <ul *ngIf="messageError?.messages">
    <li *ngFor="let message of messageError?.messages">
      {{message}}
    </li>
  </ul>
  <button class="fr-btn--close fr-btn" title="Masquer le message" (click)="isDisplay=false">Masquer le
    message
  </button>
</div>
