<nav role="navigation" class="fr-breadcrumb" aria-label="vous êtes ici :" *ngIf="!getHomePage()">
  <button class="fr-breadcrumb__button" aria-expanded="false" aria-controls="breadcrumb">Voir le fil d’Ariane</button>
  <div class="fr-collapse" id="breadcrumb">
    <ol class="fr-breadcrumb__list">
      <li>
        <a class="fr-breadcrumb__link" [routerLink]="'/home'" title="Accueil">{{'BREADCRUMB.ACCUEIL' | translate}}</a>
      </li>
      <ng-container *ngFor="let item of breadcrumbs$ | async; let last = last">
        <li *ngIf="!last">
          <a class="fr-breadcrumb__link" [routerLink]="item?.path" [title]="item?.label">{{item?.label | translate}}</a>
        </li>
      </ng-container>
      <li>
        <a class="fr-breadcrumb__link" aria-current="page" *ngIf="breadcrumbs$ | async as items">{{items[items.length
        - 1]?.label | translate}}</a>
      </li>
    </ol>
  </div>
</nav>
