import {Component, OnInit} from '@angular/core';
import packageJson from '../../../../package.json';
import {CookiesModalComponent} from '../cookies-modal/cookies-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version = '';

  constructor(private modalService: NgbModal, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.version = packageJson.version;
  }

  openCookiesModal() {
      const modalRef: NgbModalRef = this.modalService.open(CookiesModalComponent, {
        size: 'l',
        ariaLabelledBy: 'cookies-modal',
        animation: false,
        keyboard: true
      });
      modalRef.componentInstance.body = this.translateService.instant('BODY_COOKIES');
      modalRef.componentInstance.title = this.translateService.instant('TITLE_COOKIES');
      modalRef.componentInstance.okbutton = this.translateService.instant('OK');
      modalRef.componentInstance.closeButton = this.translateService.instant('CLOSE');
      modalRef.result.then(() => {
      });
  }
}
