import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {finalize} from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  service_count = 0;
  requestsNotToBeIntercept = [
    'refreshtoken',
    'infosource-inputs',
    'completude',
    'jwks',
    'oidcAccessToken'
  ];

  constructor(private spinner: NgxSpinnerService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.service_count++;
    if (this.isRequestToIntercept(req)) {
      this.spinner.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.service_count--;
        if (this.service_count === 0) {
          this.spinner.hide();
        }
      })
    );
  }

  private isRequestToIntercept(req: HttpRequest<any>): boolean {
    let toIntercept = true;

    this.requestsNotToBeIntercept.forEach(request => {
      if (req.url.includes(request)) {
        toIntercept = false;
      }
    });

    if (req.method === 'PATCH') {
      toIntercept = false;
    }

    return toIntercept;
  }

}
