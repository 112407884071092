import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastr: ToastrService, private translateService: TranslateService) {
    this.toastr.toastrConfig.enableHtml = true;
  }

  showSuccess(message, title?) {
    this.toastr.success(this.translateService.instant(message), this.translateService.instant(title));
  }

  showError(message, title?) {
    this.toastr.error(this.translateService.instant(message), this.translateService.instant(title));
  }

  showInfo(message, title?) {
    this.toastr.info(this.translateService.instant(message), this.translateService.instant(title));
  }

  showWarning(message, title?) {
    this.toastr.warning(this.translateService.instant(message), this.translateService.instant(title));
  }

  autoSuccessSave() {
    this.showSuccess('PROFIL.SAUVEGARDE', 'SUCCES');
  }

  autoErrorSave() {
    this.showError('PROFIL.SAUVEGARDE', 'ERREUR');
  }

  autoWarningSave() {
    this.showWarning('PROFIL.REQUIRED', 'MODAL_TITLE_ATTENTION');
  }
}
