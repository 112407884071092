import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {BreadcrumbItem, BreadcrumbService} from './breadcrumb.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  breadcrumbs$: Observable<BreadcrumbItem[]>;

  constructor(private breadcrumbService: BreadcrumbService, private router: Router) {
    this.breadcrumbs$ = this.breadcrumbService.items$;
  }

  getHomePage(): boolean {
    return (this.router.url === '/home' || this.router.url.includes('/accueil') || this.router.url.includes('/cgu') || this.router.url.includes('/legal-notice') || this.router.url.includes('/accessibility') || this.router.url.includes('generale') || this.router.url.includes('atelier'));
  }
}
