import {filter} from 'rxjs/operators';
import {EventTypes, PublicEventsService} from 'angular-auth-oidc-client';
import {AuthConfigModule} from './auth-config.module';
import {NgModule} from '@angular/core';
import {environment} from '../environments/environment';

@NgModule({
  imports: [AuthConfigModule]
})
export class AutorisationModule {
  constructor(private readonly eventService: PublicEventsService) {
    if (environment.production) {
      this.eventService
        .registerForEvents()
        .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
        .subscribe((config) => {
          console.log('ConfigLoaded', config);
        });
    }
  }
}
