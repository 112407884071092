import {Directive, Host, HostBinding} from '@angular/core';
import {RouterLinkActive} from '@angular/router';

@Directive({
  selector: '[routerLinkActive]'
})
export class RlaPageDirective {

  constructor(@Host() private rla: RouterLinkActive) {
  }

  get isActive() {
    return this.rla.isActive;
  }

  @HostBinding('attr.aria-current') get ariaCurrent() {
    return this.isActive ? 'Page' : undefined;
  }
}
