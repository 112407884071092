import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-legal-notice',
    templateUrl: './legal-notice.component.html',
    styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    getUrl(): string {
        return window.location.origin;
    }
}
