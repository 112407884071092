import {NgModule} from '@angular/core';
import {AuthModule, LogLevel} from 'angular-auth-oidc-client';
import {environment} from '../environments/environment';
import {authInterceptorProviders} from './services/interceptor/auth.interceptor';

const getRedirectUrl = (): string => {
    let redirectUrl = window.location.origin;
    if (!redirectUrl.endsWith('/')) {
        redirectUrl += '/';
    }

    return redirectUrl;
};

@NgModule({
    imports: [
        AuthModule.forRoot({
            config: {
                authority: environment.casServeurUrl,
                redirectUrl: getRedirectUrl(),
                postLogoutRedirectUri: getRedirectUrl(),
                clientId: environment.casClientId,
                scope: environment.casScopes,
                responseType: 'code',
                silentRenew: true,
                renewTimeBeforeTokenExpiresInSeconds: 120,
                useRefreshToken: true,
                ignoreNonceAfterRefresh: true,
                disableRefreshIdTokenAuthTimeValidation: true,
                historyCleanupOff: true,
                logLevel: environment.production ? LogLevel.None : LogLevel.Debug
            }
        })
    ],
    exports: [AuthModule],
    providers: [authInterceptorProviders]
})

export class AuthConfigModule {
}
