import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {

    constructor(private oidcSecurityService: OidcSecurityService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.oidcSecurityService.isAuthenticated$
            .pipe(
                map(authenticatedResult => authenticatedResult.isAuthenticated)
            );
    }

}
