import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const account = this.oidcSecurityService.getIdToken();
    const isLoggedIn = account?.length;
    const isApiUrl = req.url.includes(environment.backendUrl + environment.backendUri);
    if (isLoggedIn > 0 && isApiUrl) {
      req = req.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: `Bearer ${account}`
        }
      });
    } else {
      req = req.clone({
        withCredentials: true
      });
    }
    return next.handle(req);
  }
}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
];
