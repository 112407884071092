import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../models/enums/role/role';
import {AuthenticationService} from '../../services/authentication/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    @Output() clearSession: EventEmitter<boolean> = new EventEmitter<boolean>();
    localeOptions: { label: string; value: string }[];
    selectedLocale: string;
    tabs = [
        {label: 'HEADER.ACCUEIL', path: '/home', roles: [Role.CHERCHEUR]},
        {label: 'HEADER.PROGRAMMES', path: '/programme', roles: [Role.ADMIN]},
        {label: 'HEADER.CAMPAGNES', path: '/campagne', roles: [Role.ADMIN]},
        {
            label: 'HEADER.CONFIGURATION', path: '/configuration', roles: [Role.ADMIN, Role.MINISTERE], sousMenu: [
                {subLabel: 'HEADER.SUB_CONFIGURATION.SITE_FTP', subPath: '/site_ftp', roles: [Role.ADMIN]},
                {subLabel: 'HEADER.SUB_CONFIGURATION.TRANSFERT', subPath: '/transfert', roles: [Role.ADMIN]},
                {subLabel: 'HEADER.SUB_CONFIGURATION.FUSION', subPath: '/fusion_comptes', roles: [Role.ADMIN]},
                {subLabel: 'HEADER.SUB_CONFIGURATION.CONSULT_DOCUMENTS', subPath: '/consultation', roles: [Role.ADMIN]},
                {subLabel: 'HEADER.SUB_CONFIGURATION.RECHERCHE_DOCUMENTS', subPath: '/recherche_candidature', roles: [Role.ADMIN, Role.MINISTERE]}
            ]
        },
        // {label: 'Gestion des utilisateurs', path: '/user'},
    ];
    button: string;

    constructor(private translate: TranslateService, public authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('langue')) {
            this.selectedLocale = sessionStorage.getItem('langue');
        } else {
            this.selectedLocale = 'fr';
            sessionStorage.setItem('langue', this.selectedLocale);
        }
        this.translate.setDefaultLang(this.selectedLocale);
        this.localeOptions = [
            {label: 'FR - Français', value: 'fr'},
            {label: 'EN - English', value: 'en'},
        ];
        this.button = this.localeOptions.find(l => l.value === this.selectedLocale).label;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }

    useLocale(option: string) {
        this.selectedLocale = option;
        this.button = this.localeOptions.find(l => l.value === option).label;
        this.translate.use(option);
        sessionStorage.setItem('langue', this.selectedLocale);
    }

    logout() {
        this.clearSession.emit();
    }

    changeRole(role: string) {
        this.authenticationService.roleLocal.next(role);
        this.authenticationService.setAuthSessionLocal(this.authenticationService.getAuthSession());
    }
}
