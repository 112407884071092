<div>
    <h2>{{'ACCESSIBILITY.TITLE_ACCESSIBILITY' | translate}}</h2>
    <hr>
    <p>{{'ACCESSIBILITY.TEXT1_ACCESSIBILITY' | translate}}</p>
    <h3 class="fr-h5">{{'ACCESSIBILITY.TITLE_CONFORMITY' | translate}}</h3>
    <p>{{'ACCESSIBILITY.TEXT_CONFORMITY' | translate}}</p>
    <h3 class="fr-h5">{{'ACCESSIBILITY.TITLE_TECHNOLOGY' | translate}}</h3>
    <p>{{'ACCESSIBILITY.TEXT_TECHNOLOGY' | translate}}</p>
    <h3 class="fr-h5">{{'ACCESSIBILITY.TITLE_RECOURSE' | translate}}</h3>
    <p>{{'ACCESSIBILITY.TEXT1_RECOURSE' | translate}}</p>
    <p>{{'ACCESSIBILITY.TEXT2_RECOURSE' | translate}}</p>
    <p>{{'ACCESSIBILITY.TEXT3_RECOURSE' | translate}}</p>
    <p>{{'ACCESSIBILITY.TEXT4_RECOURSE' | translate}}</p>
    <p>{{'ACCESSIBILITY.TEXT5_RECOURSE' | translate}}</p>
    <p>{{'ACCESSIBILITY.TEXT6_RECOURSE' | translate}}</p>
    <p>{{'ACCESSIBILITY.TEXT7_RECOURSE' | translate}}</p>
    <p>{{'ACCESSIBILITY.TEXT8_RECOURSE' | translate}}</p>
</div>
