<div class="fr-col-12 fr-col-md-12" id="cookies-modal">
  <div class="fr-modal__body">
    <div class="fr-modal__header">
      <button class="fr-btn fr-btn--close"
              (click)="activeModal.dismiss('cancel click')">{{closeButton}}
      </button>
    </div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        {{title}}
      </h1>
      <p>{{body}}</p>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button class="fr-btn" (click)="activeModal.close('Ok click')">
            {{okbutton}}
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
