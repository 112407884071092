import {animate, style, transition, trigger} from '@angular/animations';
import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AlerteMessageService} from './alerte-message.service';
import {AlerteMessage} from '../../models/alerte-message/alerte-message';

@Component({
  selector: 'app-alerte-message',
  templateUrl: './alerte-message.component.html',
  styleUrls: ['./alerte-message.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('1s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('1s ease-in',
              style({opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class AlerteMessageComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  messageError = new AlerteMessage();
  isDisplay = false;

  constructor(private alerteMessageService: AlerteMessageService, private zone: NgZone) {
  }

  ngOnInit(): void {
    this.watcherMessageError();
    this.isDisplay = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  watcherMessageError() {
    this.alerteMessageService.messageError
      .subscribe(data => {
        this.messageError = data;
        this.isDisplay = true;
        this.zone.run(() => {
          setTimeout(() => this.isDisplay = false, 10000);
        });

      });
  }

}
