<h1 class="fr-h2">{{'CONTACT.TITRE' | translate}}</h1>
<div class="fr-grid-row fr-grid-row--gutters">
  <div class="fr-col-lg-7 fr-col-md-12">
    <div class="fr-input-group">
      <label class="fr-label" for="objet">{{'CONTACT.OBJET' | translate}}</label>
      <input class="fr-input" type="text" id="objet" name="objet" maxlength="255" [(ngModel)]="messageContact.objet">
      <span>{{messageContact?.objet?.length ? (messageContact?.objet?.length + ' / 255') : '0 / 255'}}</span>
    </div>
  </div>
  <div class="fr-col-12">
    <div class="fr-input-group">
      <label class="fr-label" for="message">{{'CONTACT.EXPLICATION' | translate}}</label>
      <textarea class="fr-input" id="message" rows="10" maxlength="3000"
                [(ngModel)]="messageContact.message"></textarea>
      <span>{{messageContact?.message?.length ? (messageContact?.message?.length + ' / 3000') : '0 / 3000'}}</span>
    </div>
  </div>

</div>
<div class="fr-grid-row fr-grid-row--center fr-mt-3w fr-mb-3w">
  <button type="button" class="fr-btn" (click)="envoyerMail()">
      {{'CONTACT.ENVOYER' | translate}}
  </button>
</div>
