import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {ContactComponent} from './modules/contact/contact.component';
import {FakePageComponent} from './components/fake-page/fake-page.component';
import {AuthorizationGuard} from './components/guards/authorization/authorization.guard';
import {Role} from './models/enums/role/role';
import {RoleGuardGuard} from './components/guards/role-guard/role-guard.guard';
import {LegalNoticeComponent} from './components/legal-notice/legal-notice.component';
import {TermsOfServiceComponent} from './components/terms-of-service/terms-of-service.component';
import {AccueilComponent} from './components/accueil/accueil.component';
import {AccessibilityComponent} from './components/accessibility/accessibility.component';

const routes: Routes = [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'legal-notice', component: LegalNoticeComponent},
    {path: 'accessibility', component: AccessibilityComponent},
    {path: 'cgu', component: TermsOfServiceComponent},
    {
        path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        data: {
            breadcrumb: 'Gestionnaire de campagne',
        },
        canActivate: [AuthorizationGuard]
    },
    {path: 'legal-notice', component: LegalNoticeComponent, outlet: 'no'},
    {path: 'accessibility', component: AccessibilityComponent, outlet: 'no'},
    {path: 'cgu', component: TermsOfServiceComponent, outlet: 'no'},
    {path: 'home', component: AccueilComponent, outlet: 'no'},
    {
        path: 'programme', loadChildren: () => import('./modules/programme/programme.module').then(m => m.ProgrammeModule),
        data: {
            breadcrumb: 'BREADCRUMB.PROGRAMMES',
            roles: [Role.ADMIN]
        },
        canActivate: [AuthorizationGuard, RoleGuardGuard]
    },
    {
        path: 'campagne', loadChildren: () => import('./modules/campagne/campagne.module').then(m => m.CampagneModule),
        data: {
            breadcrumb: 'BREADCRUMB.CAMPAGNES',
            roles: [Role.ADMIN]
        },
        canActivate: [AuthorizationGuard, RoleGuardGuard]
    },
    {
        path: 'configuration', loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule),
        data: {
            breadcrumb: 'BREADCRUMB.CONFIGURATION',
            roles: [Role.ADMIN, Role.MINISTERE]
        },
        canActivate: [AuthorizationGuard, RoleGuardGuard]
    },
    {
        path: 'user-profile', loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),
        data: {
            breadcrumb: 'BREADCRUMB.PROFIL'
        },
        canActivate: [AuthorizationGuard]
    },
    {
        path: 'candidature', loadChildren: () => import('./modules/candidature/candidature.module').then(m => m.CandidatureModule),
        data: {
            breadcrumb: 'BREADCRUMB.CANDIDATURE',
            roles: [Role.CHERCHEUR, Role.ADMIN]
        },
        canActivate: [AuthorizationGuard, RoleGuardGuard]
    },
    {
        path: 'projet', loadChildren: () => import('./modules/projet/projet.module').then(m => m.ProjetModule),
        data: {
            breadcrumb: 'BREADCRUMB.PROJET',
            roles: [Role.CHERCHEUR, Role.ADMIN, Role.MINISTERE]
        },
        canActivate: [AuthorizationGuard, RoleGuardGuard]
    },
    {
        path: 'file/:uuidFile', component: FakePageComponent,
        canActivate: [AuthorizationGuard]
    },
    {
        path: 'contact', component: ContactComponent,
        data: {
            breadcrumb: 'BREADCRUMB.CONTACT'
        },
        canActivate: [AuthorizationGuard]
    },
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false,
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
        preloadingStrategy: PreloadAllModules,
        relativeLinkResolution: 'legacy',
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
