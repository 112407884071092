import {Component, OnInit} from '@angular/core';
import {MessageContact} from './model/message-contact/message-contact';
import {takeUntil} from 'rxjs/operators';
import {ContactService} from './service/contact.service';
import {Subject} from 'rxjs';
import {ToasterService} from '../../services/toaster/toaster.service';
import {AuthenticationService} from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  messageContact: MessageContact = new MessageContact();
  private unsubscribe$ = new Subject<void>();

  constructor(private contactService: ContactService, private toasterService: ToasterService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.messageContact.emailUtilisateur = this.authenticationService.getAuthSession()?.email;
  }

  envoyerMail() {
    this.contactService.sendMailContact(this.messageContact)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.toasterService.showSuccess('CONTACT.SUCCES', 'SUCCES');
          this.messageContact = new MessageContact();
          this.messageContact.emailUtilisateur = this.authenticationService.getAuthSession()?.email;
        },
        error: () => {
          this.toasterService.showError('CONTACT.ERREUR', 'ERREUR');
        }
      });
  }

}
