import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AlerteMessage} from '../../models/alerte-message/alerte-message';

@Injectable({
  providedIn: 'root'
})
export class AlerteMessageService {
  messageError: Subject<AlerteMessage>;

  constructor() {
    this.messageError = new Subject<AlerteMessage>();
  }

  pushErrorMessage(message: AlerteMessage) {
    this.messageError.next(message);
  }
}
