import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RlaPageDirective} from './rla-page.directive';
import {SortTableDirective} from './sort-table/sort-table.directive';
import {HasRoleDirective} from './hasRole.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        RlaPageDirective,
        HasRoleDirective,
        SortTableDirective
    ],
    exports: [
        RlaPageDirective,
        HasRoleDirective,
        SortTableDirective],
})
export class DirectiveModule {
}
