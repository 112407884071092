<footer class="fr-footer" role="contentinfo" id="footer">
  <div class="fr-container--fluid fr-pl-15w fr-pr-15w">
    <div class="fr-footer__body">
      <div class="fr-footer__brand fr-enlarge-link">
        <a [routerLink]="'/'" title="Retour à l’accueil">
          <p class="fr-logo" title="république française">
            Gouvernement
            <br>Français
          </p>
        </a>
      </div>
      <div class="fr-footer__content">
        <p class="fr-footer__content-desc">
        <ul class="fr-footer__content-list">
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://legifrance.gouv.fr" title="legifrance.gouv.fr - Nouvelle fenêtre" target="_blank" rel="noopener">legifrance.gouv.fr</a>
          </li>
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://gouvernement.fr" title="gouvernement.fr - Nouvelle fenêtre" target="_blank" rel="noopener">gouvernement.fr</a>
          </li>
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://service-public.fr" title="service-public.fr - Nouvelle fenêtre" target="_blank" rel="noopener">service-public.fr</a>
          </li>
          <li class="fr-footer__content-item">
            <a class="fr-footer__content-link" href="https://data.gouv.fr" title="data.gouv.fr - Nouvelle fenêtre" target="_blank" rel="noopener">data.gouv.fr</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="fr-footer__bottom">
      <ul class="fr-footer__bottom-list">
          <li class="fr-footer__bottom-item">
              <a class="fr-footer__bottom-link"
                 [routerLink]="[{ outlets: { primary: ['accessibility'], no: ['accessibility'] } }]">{{'FOOTER.ACCESSIBILITY' | translate}}</a>
          </li>
          <li class="fr-footer__bottom-item">
              <a class="fr-footer__bottom-link"
                 [routerLink]="[{ outlets: { primary: ['legal-notice'], no: ['legal-notice'] } }]">{{'FOOTER.MENTIONS' | translate}}</a>
          </li>
          <li class="fr-footer__bottom-item">
              <a class="fr-footer__bottom-link"
                 [routerLink]="[{ outlets: { primary: ['cgu'], no: ['cgu'] } }]">{{'FOOTER.CGU' | translate}}</a>
          </li>
          <li class="fr-footer__bottom-item">
              <a class="fr-footer__bottom-link" href="javascript:void(0)" (click)="openCookiesModal()">{{'FOOTER.COOKIES' | translate}}</a>
          </li>
          <li class="fr-footer__bottom-item">
              <a class="fr-footer__bottom-link"
                 [routerLink]="'contact'">{{'FOOTER.CONTACT' | translate}}</a>
          </li>
          <li class="fr-footer__bottom-item">
              <span class="fr-footer__bottom-link">{{version}}</span>
          </li>
      </ul>
      <div class="fr-footer__bottom-copy">
        <p>{{'FOOTER.CONTENT' | translate}}<a
            href="https://github.com/etalab/licence-ouverte/blob/master/LO.md" target="_blank" rel="noopener">licence etalab-2.0</a>
        </p>
      </div>
    </div>
  </div>
</footer>
