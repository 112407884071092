<header role="banner" class="fr-header">
  <div class="fr-header__body">
    <div class="fr-container">
      <div class="fr-header__body-row">
        <div class="fr-header__brand fr-enlarge-link">
          <div class="fr-header__brand-top">
            <div class="fr-header__logo">
              <p class="fr-logo">
                Gouvernement
                <br>français
              </p>
            </div>
            <div class="fr-header__operator">
              <a [routerLink]="'/'" title="Logo Campus">
                <img class="fr-responsive-img" style="width:40%" src="../../../assets/LOGO_CF_CARRE_COULEUR.png"
                     alt="Logo Campus"/>
              </a>
            </div>
            <div class="fr-header__navbar">
              <button class="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="modal-476" aria-haspopup="menu"
                      id="button-477" title="Menu">
                Menu
              </button>
            </div>
          </div>
        </div>
        <div class="fr-header__tools">
          <div class="fr-header__tools-links">
            <ul class="fr-btns-group">
              <li *ngIf="authenticationService.getAuthSession()?.habilitation?.habilitationLibelle === 'CHERCHEUR'">
                  <a class="fr-btn fr-icon-account-circle-fill" title="Consulter son profil"
                     [routerLink]="'/user-profile'">{{'HEADER.PROFIL' | translate}}</a>
              </li>
                <li>
                    <a [routerLink]="'/home'" (click)="logout()"
                       aria-label="Bouton déconnecter" class="fr-btn txt-blue"><em
                        class="fr-icon-logout-box-r-fill"></em>
                        {{'HEADER.DECONNEXION' | translate}}</a>
                </li>
            </ul>
            <nav role="navigation" aria-label="Sélection de la langue" class="fr-translate fr-nav"
                 title="Langue sélectionnée">
              <div class="fr-nav__item">
                <button class="fr-translate__btn fr-btn" aria-controls="translate-516" aria-expanded="false"
                        title="Sélectionner une langue">
                  {{button}}
                </button>
                <div class="fr-collapse fr-translate__menu fr-menu" id="translate-516">
                  <ul class="fr-menu__list">
                    <li *ngFor="let locale of localeOptions">
                      <button class="fr-translate__language fr-nav__link" hreflang="{{locale.value}}"
                              lang="{{locale.value}}"
                              (click)="useLocale(locale.value)"
                              [attr.aria-current]="selectedLocale === locale.value? 'page' : undefined"
                              title="{{locale.label}}">{{locale.label}}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fr-header__menu fr-modal" id="modal-476" aria-labelledby="button-477">
    <div class="fr-container--fluid fr-pl-15w fr-pr-15w">
      <button class="fr-btn--close fr-btn" aria-controls="modal-476" title="Fermer">
        Fermer
      </button>
        <div class="fr-header__menu-links" style="display: none">
        </div>
        <div class="fr-header__menu-links-custom">
          <ul class="fr-btns-group">
              <li *ngIf="authenticationService.getAuthSession()?.habilitation?.habilitationLibelle === 'CHERCHEUR'">
                  <a class="fr-btn fr-icon-account-circle-fill" title="Consulter son profil"
                     [routerLink]="'/user-profile'">{{'HEADER.PROFIL' | translate}}</a>
              </li>
              <li>
                  <a [routerLink]="'/home'" (click)="logout()"
                     aria-label="Bouton déconnecter" class="fr-btn txt-blue fr-icon-logout-box-r-fill">
                      {{'HEADER.DECONNEXION' | translate}}</a>
              </li>
          </ul>
          <nav role="navigation" aria-label="Sélection de la langue" class="fr-translate fr-nav"
               title="Langue sélectionnée">
              <div class="fr-nav__item">
                  <button class="fr-translate__btn fr-btn" aria-controls="translate-517" aria-expanded="false"
                          title="Sélectionner une langue">
                      {{button}}
                  </button>
                  <div class="fr-collapse fr-translate__menu fr-menu" id="translate-517">
                      <ul class="fr-menu__list">
                          <li *ngFor="let locale of localeOptions">
                              <button class="fr-translate__language fr-nav__link" hreflang="{{locale.value}}"
                                      lang="{{locale.value}}"
                                      (click)="useLocale(locale.value)"
                                      [attr.aria-current]="selectedLocale === locale.value? 'page' : undefined"
                                      title="{{locale.label}}">{{locale.label}}
                              </button>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>
        </div>
      <nav class="fr-nav" id="navigation-494" role="navigation" aria-label="Menu principal">
        <ul class="fr-nav__list">
          <li class="fr-nav__item" *ngFor="let item of tabs; let index = index;">
            <ng-container
              *ngIf="item?.sousMenu &&
              item?.roles?.includes(authenticationService.getAuthSession()?.habilitation?.habilitationLibelle)">
              <button class="fr-nav__btn" aria-expanded="false" [attr.aria-label]="item.label"
                      title="Onglet - {{item.label | translate}}" [attr.aria-controls]="'menu'-{index}">{{item.label
                | translate}}</button>
              <div class="fr-collapse fr-menu" id="{{'menu'-index}}">
                <ul class="fr-menu__list">
                  <ng-container *ngFor="let subItem of item.sousMenu">
                    <li
                      *ngIf="subItem.roles?.includes(authenticationService.getAuthSession()?.habilitation?.habilitationLibelle)">
                      <a
                        class="fr-nav__link" [routerLink]="[item.path + subItem.subPath]" target="_self"
                        title="Onglet - {{subItem.subLabel | translate}}" ariaCurrentWhenActive="page"
                        routerLinkActive="active">{{subItem.subLabel | translate}}</a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
              <ng-container *ngIf="!item?.sousMenu &&
                item?.roles?.includes(authenticationService.getAuthSession()?.habilitation?.habilitationLibelle)">
                  <a
                      class="fr-nav__link"
                      [routerLink]="[item.path]"
                      [attr.aria-label]="item.label"
                      routerLinkActive="active" ariaCurrentWhenActive="page" title="Onglet - {{item.label | translate}}"
                      [routerLinkActiveOptions]="{ exact: true }">{{item.label | translate}}</a>
              </ng-container>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
