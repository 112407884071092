import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import _ from 'lodash';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const roleAllowed = this.authenticationService.getAuthSession()?.habilitation?.habilitationLibelle;
    // const roleAllowed = this.authenticationService?.roleLocal ? this.authenticationService.roleLocal : this.authenticationService.getAuthSession()?.habilitation?.habilitationLibelle;
    console.log('roleAllowed', roleAllowed);
    console.log('route.data.roles', route.data.roles);
    if (route.data.roles) {
      for (const habilitation of route.data.roles) {
        const include: boolean = _.includes(roleAllowed, habilitation);
        console.log('include', include);
        if (include) {
          return true;
        }
      }
    }
    return false;
  }
}
