import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();

    constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {
    }

    ngOnInit(): void {
        this.oidcSecurityService.checkAuth()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({isAuthenticated}) => {
                if (isAuthenticated) {
                    if (localStorage.getItem('file')) {
                        this.router.navigate(['/file', localStorage.getItem('file')]);
                    } else if (window.location.pathname === '/' || window.location.pathname.endsWith('/')) {
                        this.router.navigate([{outlets: {primary: 'home'}}]);
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }

}
