export class Endpoints {
    static PROGRAMME = 'programme';
    static PARAMETRE_FTP = 'parametreFtp';
    static PAYS = 'pays';
    static CAMPAGNE = 'campagne';
    static PROLONGATION_CAMPAGNE = 'prolongationCampagne';
    static EVALUATION_CANDIDATURE = 'evalutationcandidature';
    static ENVELOPPE = 'enveloppe';
    static PASSAGE_GESTION = 'passagegestion';
    static NON_RENOUVELE = 'nonrenouvele';
    static DEROGATION_UTILISATEUR = 'derogationUtilisateur';
    static LISTE_DIFFUSION = 'listeDiffusion';
    static UTILISATEUR = 'utilisateur';
    static LABORATOIRE = 'laboratoire';
    static BUDGET_CAMPAGNE = 'budgetcampagne';
    static BUDGET_CAMPAGNE_ATELIER = 'budgetcampagne/atelier';
    static DEVISE = 'devise';
    static CANDIDATURE_GENERALE = 'candidature/generale';
    static CANDIDATURE_ATELIER = 'candidature/atelier';
    static CANDIDATURE = 'candidature';
    static CANDIDATURES = 'candidatures';
    static CANDIDATURE_TEAM = 'equipe';
    static CANDIDATURE_VALIDER_MEMBRES = 'validerMembres';
    static CANDIDATURE_BUDGET = 'budget';
    static CANDIDATURE_BUDGET_RECAP = 'recap';
    static CANDIDATURE_THESE = 'these';
    static CANDIDATURE_FILE = 'file';
    static FILE = 'file';
    static GENERATE_PDF = 'generate';
    static GENERATE_UUID = 'generate/uuid';
    static EXCEL = 'excel';
    static PDF = 'pdf';
    static CANDIDATURE_DOCUMENT = 'document';
    static CANDIDATURE_CV = 'cv';
    static CANDIDATURE_VALIDER = 'valider';
    static CANDIDATURE_DEPOT = 'depot';
    static DOMAINE = 'domaine';
    static RNSR = 'rnsr';
    static RAPPORT_INTER = 'rapports_inter';
    static RAPPORT_FINAL = 'rapports_finaux';
    static COMPLETUDE = 'completude';
    static FICHE = 'fiche';
    static COLLABORANT = 'collaborant';
    static ANNULER_DEPOT = 'annulerDepot';
    static PROTECTION_DONNEES = 'protectiondonnees';
    static EMAIL = 'email';
    static PROJECT = 'projet';
    static CONSULTATION = 'consultation';
    static MOBILITE = 'mobilite';
    static RAPPORT = 'rapport';
    static SOLDE = 'solde';
    static CONTACT = 'contact';
    static RECHERCHE = 'recherche';
    static TRANSFERT = 'transfert';
    static DOWNLOAD = 'download';
    static TUTELLES = 'tutelles';
    static DETAIL = 'detail';
    static FUSION = 'fusion';
    static INFOS = 'infos';
    static LISTE_UTILISATEURS = 'listeUtilisateurs';
    static VERIFIER_TITRE = 'verifierTitre';
    static LOG = 'log';
}
