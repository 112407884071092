import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app-components/app.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {HeaderModule} from './components/header/header.module';
import {FooterModule} from './components/footer/footer.module';
import {BreadcrumbModule} from './components/breadcrumb/breadcrumb.module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AlerteMessageComponent} from './components/alerte-message/alerte-message.component';
import {ToastrModule} from 'ngx-toastr';
import {registerLocaleData} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {LoaderInterceptor} from './services/loader.interceptor';
import {AutosizeModule} from 'ngx-autosize';
import {FakePageComponent} from './components/fake-page/fake-page.component';
import {of} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import {HeaderAccueilComponent} from './components/accueil/header-accueil/header-accueil.component';
import {LegalNoticeComponent} from './components/legal-notice/legal-notice.component';
import {TermsOfServiceComponent} from './components/terms-of-service/terms-of-service.component';
import {AccessibilityComponent} from './components/accessibility/accessibility.component';
import {QuillModule} from 'ngx-quill';
import {environment} from '../environments/environment';
import {AutorisationModule} from './autorisation.module';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        AlerteMessageComponent,
        FakePageComponent,
        HeaderAccueilComponent,
        LegalNoticeComponent,
        TermsOfServiceComponent,
        AccessibilityComponent
    ],
    imports: [
        AutorisationModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        AutosizeModule,
        HeaderModule,
        FooterModule,
        NgSelectModule,
        BreadcrumbModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            progressBar: true,
            preventDuplicates: true
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbProgressbarModule,
        QuillModule.forRoot()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        TranslateService,
        NgxSpinnerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        }
    ],

    // providers: [
    //   GlobalHandleHttpError,
    //   {
    //     provide: ErrorHandler,
    //     useClass: GlobalHandleHttpError,
    //   }
    // ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
