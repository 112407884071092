<div>
    <h2>{{'CONDITION_GENERALE.TITRE_1' | translate}}</h2>
    <hr>
    <p>{{'CONDITION_GENERALE.P_1' | translate}}</p>

    <p><strong>{{'CONDITION_GENERALE.P_2' | translate}}</strong></p>

    <p>{{'CONDITION_GENERALE.P_3' | translate}}</p>

    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_2' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_4' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_5' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_6' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_7' | translate}}</p>

    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_3' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_8' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_9' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_10' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_11' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_12' | translate}}</p>

    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_4' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_13' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_14' | translate}} <a href="#">Politique de confidentialité.</a></p>

    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_5' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_15' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_16' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_17' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_18' | translate}}</p>


    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_6' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_19' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_20' | translate}}</p>

    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_7' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_21' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_22' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_23' | translate}} <a
        href="mailto:extranet@campusfrance.org">extranet@campusfrance.org</a>.
    </p>

    <p>{{'CONDITION_GENERALE.P_24' | translate}}</p>

    <p>{{'CONDITION_GENERALE.P_25' | translate}}</p>

    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_8' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_26' | translate}}</p>

    <h3 class="fr-h5">{{'CONDITION_GENERALE.TITRE_9' | translate}}</h3>

    <p>{{'CONDITION_GENERALE.P_27' | translate}}</p>
</div>
