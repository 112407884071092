<header role="banner" class="fr-header">
    <div class="fr-header__body">
        <div class="fr-container--fluid fr-pl-15w fr-pr-15w">
            <div class="fr-header__body-row">
                <div class="fr-header__brand fr-enlarge-link">
                    <div class="fr-header__brand-top">
                        <div class="fr-header__logo">
                            <p class="fr-logo">
                                Gouvernement
                                <br>français
                            </p>
                        </div>
                        <div class="fr-header__operator">
                            <a [routerLink]="'/'" title="Logo Campus">
                                <img class="fr-responsive-img" style="width:20%"
                                     src="../../../../assets/LOGO_CF_CARRE_COULEUR.png"
                                     alt="Logo Campus"/>
                            </a>
                        </div>
                        <div class="fr-header__navbar">
                            <button class="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="modal-499"
                                    aria-haspopup="menu" id="button-500" title="Menu">
                                Menu
                            </button>
                        </div>
                    </div>
                </div>
                <div class="fr-header__tools">
                    <div class="fr-header__tools-links">
                        <ul class="fr-btns-group">
                            <li>
                                <button (click)="sInscrire()" aria-label="Bouton s'inscrire"
                                        class="fr-btn txt-blue fr-icon-edit-line">
                                    {{'HEADER.INSCRIPTION' | translate}}
                                </button>
                            </li>
                            <li>
                                <button (click)="seConnecterButton()"
                                        class="fr-btn fr-btn--tertiary-no-outline--icon-right fr-icon-account-circle-line">
                                    {{'HEADER.CONNEXION' | translate}}
                                </button>
                            </li>
                        </ul>
                        <nav role="navigation" aria-label="Sélection de la langue" class="fr-translate fr-nav"
                             title="Langue sélectionnée">
                            <div class="fr-nav__item">
                                <button class="fr-translate__btn fr-btn" aria-controls="translate-516"
                                        aria-expanded="false"
                                        title="Sélectionner une langue">
                                    {{button}}
                                </button>
                                <div class="fr-collapse fr-translate__menu fr-menu" id="translate-516">
                                    <ul class="fr-menu__list">
                                        <li *ngFor="let locale of localeOptions">
                                            <button class="fr-translate__language fr-nav__link"
                                                    hreflang="{{locale.value}}"
                                                    lang="{{locale.value}}"
                                                    (click)="useLocale(locale.value)"
                                                    [attr.aria-current]="selectedLocale === locale.value? 'page' : undefined"
                                                    title="{{locale.label}}">{{locale.label}}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div class="fr-header__menu fr-modal" id="modal-499" aria-labelledby="button-500" >
        <div class="fr-container--fluid fr-pl-15w fr-pr-15w">
            <button aria-controls="modal-499" class="fr-btn--close fr-btn" title="Fermer">
                {{'CLOSE' | translate}}
            </button>
            <div class="fr-header__menu-links-custom">
                <ul class="fr-btns-group">
                    <li>
                        <button (click)="sInscrire()" aria-label="Bouton s'inscrire"
                                class="fr-btn txt-blue fr-icon-edit-line">
                            {{'HEADER.INSCRIPTION' | translate}}
                        </button>
                    </li>
                    <li>
                        <button (click)="seConnecterButton()"
                                class="fr-btn fr-btn--tertiary-no-outline--icon-right fr-icon-account-circle-line">
                            {{'HEADER.CONNEXION' | translate}}
                        </button>
                    </li>
                </ul>
                <nav role="navigation" aria-label="Sélection de la langue" class="fr-translate fr-nav"
                     title="Langue sélectionnée">
                    <div class="fr-nav__item">
                        <button class="fr-translate__btn fr-btn" aria-controls="translate-517"
                                aria-expanded="false"
                                title="Sélectionner une langue">
                            {{button}}
                        </button>
                        <div class="fr-collapse fr-translate__menu fr-menu" id="translate-517">
                            <ul class="fr-menu__list">
                                <li *ngFor="let locale of localeOptions">
                                    <button class="fr-translate__language fr-nav__link"
                                            hreflang="{{locale.value}}"
                                            lang="{{locale.value}}"
                                            (click)="useLocale(locale.value)"
                                            [attr.aria-current]="selectedLocale === locale.value? 'page' : undefined"
                                            title="{{locale.label}}">{{locale.label}}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>

</header>
