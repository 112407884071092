import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Endpoints} from '../../models/enums/endpoints/endpoints';
import {FileDbDTO} from '../../models/fileDbDTO/file-db-dto';
import {takeUntil} from 'rxjs/operators';
import {PathFolderGcp} from '../../models/enums/path-folder-gcp/path-folder-gcp';

@Injectable({
  providedIn: 'root'
})
export class FileService implements OnDestroy {

  private unsubscribe$ = new Subject<void>();

  constructor(private http: HttpClient) {
  }

  findFile(idFile: number): Observable<FileDbDTO> {
    return this.http.get<FileDbDTO>(`${environment.backendUrl}${environment.backendUri}/${Endpoints.FILE}/${idFile}`);
  }

  generatePdf(idCandidature: number): Observable<string> {
    return this.http.get<string>(`${environment.backendUrl}${environment.backendUri}/${Endpoints.FILE}/${Endpoints.GENERATE_PDF}/${idCandidature}`);
  }

  generateFileUuid(uuidFile: string): Observable<string> {
    return this.http.get<string>(`${environment.backendUrl}${environment.backendUri}/${Endpoints.FILE}/${Endpoints.GENERATE_UUID}/${uuidFile}`);
  }

  downloadPdf(idCandidature: number) {
    this.generatePdf(idCandidature)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          window.open(data, '_blank', 'noopener');
        }
      });
  }

  uploadFile(file: File, idTheme: number, pathFolder: PathFolderGcp): Observable<FileDbDTO> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('idTheme', idTheme !== null ? idTheme.toString() : null);
    formData.append('pathFolder', JSON.stringify(pathFolder));
    return this.http.post<FileDbDTO>(`${environment.backendUrl}${environment.backendUri}/${Endpoints.FILE}`, formData);
  }

  deleteFile(idFile: number, idCandidature: number): Observable<number> {
    return this.http.delete<number>(
      `${environment.backendUrl}${environment.backendUri}/${Endpoints.FILE}/${idFile}`, {body: idCandidature});
  }

  getUrlFile(idFile: number): Observable<string> {
    return this.http.get(`${environment.backendUrl}${environment.backendUri}/${Endpoints.FILE}/${idFile}/download`, {responseType: 'text'});
  }

  downloadFile(idFile: number) {
    this.getUrlFile(idFile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          window.open(data, '_blank', 'noopener');
        }
      });
  }

  downloadFilee(idFile: number, fullName?: boolean) {
    this.findFile(idFile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (file) => {
          const source = `data:${file.contentType};base64,${file.data}`;
          const link = document.createElement('a');
          link.href = source;
          if (fullName) {
            link.download = `${file.name}`;
          } else {
            link.download = `${file.name.slice(14)}`;
          }
          link.click();
        }
      });
  }

  urltoFile(url, filename, mimeType) {
    return (fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, {type: mimeType});
        })
    );
  }

  fileCreationToDownload(file: FileDbDTO, type: string) {
    if (type === 'pdf') {
      type = 'application/pdf';
    } else if (type === 'zip') {
      type = 'application/zip';
    } else if (type === 'csv') {
      type = 'text/csv';
    } else {
      type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    const link = document.createElement('a');
    link.href = 'data:' + type + ';base64,' + encodeURIComponent(file.data);
    link.setAttribute('download', file.name);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
