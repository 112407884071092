export const environment = {
    production: false,
    backendUrl: '/back',
    backendUri: '',
    activate_log: '',
    log_level: '',
    mail_test: 'test@campus.fr',
    log_classes: [],
    casServeurUrl: 'https://recette-auth.campusfrance.org/cas/oidc',
    casClientId: 'eclectus-dev',
    casScopes: 'openid offline_access',
    urlCreateUser: 'https://recette-admin-auth.campusfrance.org/public/users/create'
};
