import {Component, OnInit} from '@angular/core';
import {FileService} from '../file/file.service';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
    selector: 'app-fake-page',
    templateUrl: './fake-page.component.html',
    styleUrls: ['./fake-page.component.scss']
})
export class FakePageComponent implements OnInit {

    private unsubscribe$ = new Subject<void>();
    uuidFile: string;

    constructor(private fileService: FileService, private route: ActivatedRoute, private oidcSecurityService: OidcSecurityService) {
    }

    ngOnInit(): void {
        const routeParams = this.route.snapshot.paramMap;
        this.uuidFile = routeParams.get('uuidFile');
        this.fileService.generateFileUuid(this.uuidFile)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (data) => {
                    localStorage.removeItem('file');
                    window.open(data, '_parent', 'noopener');
                }
            });
    }

}
