<div>
    <h2>{{'MENTION_LEGALE.TITLE' | translate}}</h2>
    <hr>
    <p>{{'MENTION_LEGALE.P_1' | translate}} <a [routerLink]="'/'">{{getUrl()}}</a> {{'MENTION_LEGALE.P_2' | translate}}
    </p>

    <p><strong>{{'MENTION_LEGALE.STRONG_1' | translate}}</strong> 28 rue de la Grange aux Belles, 75010 Paris, France.
    </p>

    <p><strong>{{'MENTION_LEGALE.STRONG_2' | translate}}</strong> 01 40 40 58 58</p>

    <p><strong>{{'MENTION_LEGALE.STRONG_3' | translate}}</strong> extranet@campusfrance.org</p>

    <p><strong>{{'MENTION_LEGALE.STRONG_4' | translate}}</strong> {{'MENTION_LEGALE.P_3' | translate}}</p>

    <p>{{'MENTION_LEGALE.P_4' | translate}}</p>

    <p><strong>{{'MENTION_LEGALE.STRONG_5' | translate}}</strong> 23 rue du dessous des Berges – 75013 Paris France</p>
    <p><strong>{{'MENTION_LEGALE.STRONG_6' | translate}}</strong> contact@owns.fr</p>

</div>
