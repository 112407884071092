import {Component, OnDestroy, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    isAuthenticated$: Observable<boolean>;
    private unsubscribe$ = new Subject<void>();

    title = 'Campus France - Eclectus';

    constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {
    }

    ngOnInit(): void {
        if (window.location.href.includes('/file/')) {
            localStorage.setItem('file', window.location.href.split('/').slice(-1).pop());
        }
        this.oidcSecurityService.checkAuth()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({isAuthenticated}) => {
                if (isAuthenticated) {
                    if (localStorage.getItem('file')) {
                        this.router.navigate(['/file', localStorage.getItem('file')]);
                    } else if (window.location.pathname === '/' || window.location.pathname.endsWith('/')) {
                        this.router.navigate([{outlets: {primary: 'home'}}]);
                    }
                } else {
                    if (localStorage.getItem('file')) {
                        this.seConnecter();
                    }
                    this.router.navigate([{outlets: {no: 'home'}}]);
                }
            });

        this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
            map(authenticatedResult => authenticatedResult.isAuthenticated)
        );
    }

    logout() {
        sessionStorage.removeItem('authUser');
        console.log('APPEL DU SERVICE logoffAndRevokeTokens');
        this.oidcSecurityService.logoffAndRevokeTokens()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                console.log('data', data);
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }

    seConnecter() {
        if (!this.oidcSecurityService.isAuthenticated()) {
            this.oidcSecurityService.authorize();
        }
    }
}

